@import 'styles/_colors.scss';

.Terminal {
  font-family: Inconsolata, monospace;
  color: $cmd-line-grey;

  pre {
    color: $cmd-line-grey;
  }
}
